<app-breadcrumb></app-breadcrumb>
<div class="container justify-content-center pb-5 pt-5" role="main">
  <div>
    <h2 class="display-4">Quiero comprar</h2>
    <p class="lead text-center">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
      molestie odio ut ex ullamcorper varius. Aliquam laoreet sed elit id
      vestibulum. Duis dolor elit, sollicitudin at lorem eu, porttitor aliquam
      odio. Nullam bibendum justo nec venenatis egestas. Fusce rutrum tempor
      nisl. Morbi pretium libero laoreet vulputate molestie. Nulla facilisi.
      Fusce sollicitudin tempus quam eu rhoncus.
    </p>
  </div>
</div>
<app-help-button></app-help-button>
