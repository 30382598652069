<app-breadcrumb></app-breadcrumb>
<div class="container justify-content-center pb-5 pt-5" role="main">
  <div id="rondas">
    <h2 class="display-4 text-center pb-3">Rondas de vinculación comercial</h2>
    <div class="row align-items-center">
      <div class="yt-video col-md-5"></div>
      <div class="col-md-7">
        <p class="lead text-center">
          Las rondas de vinculación comercial son encuentros con proveedores de
          impacto social, económico, ambiental y de género para incorporarlas al
          sistema de proveedores o a tu cadena de valor.
        </p>
        <p class="lead text-center">
          Conocé la experiencia de (nombre empresa) con (nombre UP)
        </p>
      </div>
    </div>
  </div>

  <div id="ferias" class="container justify-content-center pb-5 pt-5">
    <h2 class="display-4 text-center pb-3">Ferias</h2>
    <div class="row align-items-center">
      <div class="yt-video col-md-5"></div>
      <div class="col-md-7">
        <p class="lead text-center">
          Las ferias de la Ciudad conectan al consumidor final con el proveedor
          de la de impacto.
        </p>
        <p class="lead text-center">
          Conocé la experiencia de (nombre UP) en la feria (nombre de la feria)
          de la Ciudad.
        </p>
      </div>
    </div>

    <div id="casos-de-exito" class="container justify-content-center pb-5 pt-5">
      <h2 class="display-4 text-center pb-3">Casos de éxito</h2>
      <p class="lead text-center">
        Conocé la experiencia de de vinculación comercial entre distintas
        empresas y proveedores de impacto social, económico, ambiental y de
        género para seguir generando +ValorBA
      </p>
      <div class="row d-flex justify-content-around">
        <div class="yt-video col-md-5"></div>
        <div class="yt-video col-md-5"></div>
      </div>
    </div>
  </div>
  <app-help-button></app-help-button>
</div>
