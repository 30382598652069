import { HttpParams } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  SimpleChange,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EPublishmentTypes } from 'src/app/core/enums/publishment-type';
import { IInitialData } from 'src/app/core/models/initial-data';
import { ILocation } from 'src/app/core/models/location';
import { IPublishment } from 'src/app/core/models/publishment';
import { IRubric } from 'src/app/core/models/rubric';
import { GeneralService } from 'src/app/core/services/http/general.service';
import { SearchService } from 'src/app/core/services/http/search.service';

import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { ENTER } from '@angular/cdk/keycodes';
import { ListLocationsComponent } from 'src/app/shared/components/list-locations/list-locations.component';
import { locationExists } from 'src/app/shared/validators/validatorLocation';
import { ToastComponent } from 'src/app/shared/components/toast/toast.component';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
})
export class SearchFormComponent implements OnInit {
  searchForm: FormGroup;
  selectedCategory: boolean = false;
  rubrics: IRubric[];
  dataSearch: IInitialData;
  enumPublishmentTypes = EPublishmentTypes;
  @Output() results: EventEmitter<IPublishment[]> = new EventEmitter<
    IPublishment[]
  >();
  locationResults: ILocation[];
  locacionSelected: number;
  currentPage: number;
  orderSelected: number = 0;
  fromHeader: boolean = false;

  @ViewChildren(ListLocationsComponent)
  locations: QueryList<ListLocationsComponent>;
  private keyManager: ActiveDescendantKeyManager<ListLocationsComponent>;
  @ViewChild('submit') Element: ElementRef;
  @ViewChild('section') Section: ElementRef;
  @ViewChild('input') Input: ElementRef;

  ngAfterViewInit() {
    this.keyManager = new ActiveDescendantKeyManager(this.locations)
      .withWrap()
      .withTypeAhead();
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private generalService: GeneralService,
    private searchService: SearchService,
    private toastError: ToastService
  ) {}

  ngOnInit(): void {
    if (this.router.url.startsWith('/search')) {
      this.route.queryParams.subscribe((params) => {
        if (Object.keys(params).length !== 0) {
          this.fromHeader = params.fh;
          this.searchHTTPParams(params);
        } else {
          if (localStorage.getItem('searchFilters')) {
            this.searchStoreFilters();
          } else {
            this.getInitialData();
          }
        }
      });
    } else if (this.router.url.startsWith('/?tipoPublicacion_id')) {
      this.route.queryParams.subscribe((params) => {
        // console.log(params);
        // this.getInitialDataHome(params);
        this.getInitialData(params);
      });
    } else {
      this.getInitialData();
    }
  }

  searchHTTPParams(params) {
    this.getInitialData(params);

    const body = new HttpParams()
      .set(
        'tipoPublicacion_id',
        params['tipoPublicacion_id'] ? params['tipoPublicacion_id'] : null
      )
      .set('rubro_id', params['rubro_id'] ? params['rubro_id'] : null)
      .set('query', params['query'] ? params['query'] : '')
      .set('barrio_id', params['barrio_id'] ? params['barrio_id'] : null);

    this.searchService.search(body).subscribe((res) => {
      this.currentPage = res['resultados']['current_page'];
      this.results.emit(res);
    });
  }

  getInitialDataHome(params) {
    this.buildForm();
    if (params) {
      this.selectValues(params);
    }
  }

  getInitialData(params?) {
    this.generalService.getDataSearch().subscribe((res) => {
      this.dataSearch = res;
      this.buildForm();
      if (params) {
        this.selectValues(params);
      }
    });
  }

  selectValues(values) {
    this.searchForm.controls.type.setValue(values['tipoPublicacion_id'] || '');
    this.enableControls();
    this.searchForm.controls.rubric.setValue(values['rubro_id'] || '');
    if (values['query']) {
      this.searchForm.controls.query.setValue(values['query']);
    }
    if (values['barrio_id']) {
      this.locacionSelected = values['barrio_id'];
      this.searchForm.controls.location.setValue(
        this.getLocationById(values['barrio_id'])
      );
    }
  }

  enableControls() {
    this.searchForm.get('rubric').setValue('');
    this.searchForm.get('query').setValue('');
    this.searchForm.get('location').setValue('');
    this.locationResults = [];
    this.locacionSelected = null;

    if (this.searchForm.controls.type.value === '') {
      this.searchForm.controls.rubric.disable();
      this.searchForm.controls.query.disable();
      this.searchForm.controls.location.disable();
      this.searchForm.get('type').setValue('');
    } else {
      this.searchForm.controls.rubric.enable();
      this.searchForm.controls.query.enable();
      this.searchForm.controls.location.enable();
      this.rubrics = this.getRubricsForType(this.searchForm.get('type').value);
    }

    console.log(this.searchForm.controls.type);
  }

  buildForm() {
    this.searchForm = new FormGroup({
      type: new FormControl('', Validators.required),
      rubric: new FormControl({ value: '', disabled: true }),
      query: new FormControl({ value: '', disabled: true }, [
        Validators.minLength(3),
        Validators.maxLength(100),
      ]),
      location: new FormControl(
        { value: '', disabled: true },
        locationExists()
      ),
    });
  }

  onSubmit() {
    if (
      this.Element.nativeElement.className.split(' ').indexOf('disabled') > 0 &&
      !this.fromHeader
    ) {
      this.toastError.show('Error: Faltan completar campos.');
      new ToastComponent(this.toastError);
      setTimeout(() => {
        this.toastError.removeAll();
      }, 5000);
      return;
    }
    this.setFiltersInStoreService();

    if (!this.router.url.includes('/search')) {
      this.router.navigate(['/search'], {
        queryParams: {
          tipoPublicacion_id: this.searchForm.get('type').value,
          rubro_id: this.searchForm.get('rubric').value,
          query: this.searchForm.get('query').value,
          barrio_id: this.locacionSelected,
        },
      });
    } else {
      const body = new HttpParams()
        .set('tipoPublicacion_id', this.searchForm.get('type').value)
        .set('rubro_id', this.searchForm.get('rubric').value)
        .set('query', this.searchForm.get('query').value)
        .set(
          'barrio_id',
          this.locacionSelected ? this.locacionSelected.toString() : null
        )
        .set('page', this.currentPage.toString())
        .set('orden', this.orderSelected.toString());

      this.searchService.search(body).subscribe((res) => {
        this.currentPage = res['resultados']['current_page'];
        this.results.emit(res);
      });
    }
  }

  onKeydown(event) {
    if (event.key == 'ArrowDown') {
      let active = this.Section.nativeElement.querySelector(
        'app-list-locations.active'
      );
      let parse = active ? active.id.split('_') : 0;
      let id = '#location_' + (parse[1] ? parseInt(parse[1]) + 1 : 0);
      let location = this.Section.nativeElement.querySelector(id);
      this.Input.nativeElement.ariaLabel = location ? location.ariaLabel : '';
    }
    if (event.key == 'ArrowUp') {
      let active = this.Section.nativeElement.querySelector(
        'app-list-locations.active'
      );
      let parse = active ? active.id.split('_') : 0;
      let id = '#location_' + (parse[1] ? parseInt(parse[1]) - 1 : 0);
      let location = this.Section.nativeElement.querySelector(id);
      this.Input.nativeElement.ariaLabel = location ? location.ariaLabel : '';
    }
  }

  searchStoreFilters() {
    const filters = JSON.parse(localStorage.getItem('searchFilters'));
    this.getInitialData(filters);

    const body = new HttpParams()
      .set('tipoPublicacion_id', filters.tipoPublicacion_id.toString())
      .set('rubro_id', filters.rubro_id ? filters.rubro_id.toString() : null)
      .set('query', filters.query ? filters.query : '')
      .set(
        'barrio_id',
        filters.barrio_id ? filters.barrio_id.toString() : null
      );

    this.searchService.search(body).subscribe((res) => {
      this.currentPage = res['resultados']['current_page'];
      this.results.emit(res);
    });
  }

  getRubricsForType(type: string) {
    switch (type) {
      case '1':
        return this.dataSearch.rubrosProductos;
      case '2':
        return this.dataSearch.rubrosServicios;
      case '3':
        return this.dataSearch.rubrosEmprendedores;
    }
  }

  getLocationById(id) {
    const barrio: ILocation[] = this.dataSearch.barrios.filter((barrio) => {
      return barrio.id === parseInt(id);
    });
    return barrio[0].nombre;
  }

  listLocations() {
    this.locationResults = [];
    this.locacionSelected = null;

    this.locationResults = this.dataSearch.barrios.filter((barrio) => {
      return barrio.nombre.toLowerCase();
    });
  }

  hideLocations() {
    this.locationResults = [];
  }

  searchLocation(event) {
    if (event.keyCode === ENTER) {
      const location = this.keyManager.activeItem.location;
      this.selectLocation(location);
    } else {
      this.keyManager.onKeydown(event);
    }
  }

  selectLocation(location: ILocation) {
    this.searchForm.get('location').setValue(location.nombre);
    this.locacionSelected = location.id;
  }

  setFiltersInStoreService() {
    const filters = {
      tipoPublicacion_id: this.searchForm.get('type').value,
      rubro_id: this.searchForm.get('rubric').value,
      query: this.searchForm.get('query').value,
      barrio_id: this.locacionSelected,
    };
    localStorage.setItem('searchFilters', JSON.stringify(filters));
  }
}
