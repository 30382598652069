import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CatalogComponent } from './modules/catalog/catalog.component';
import { HomeComponent } from './modules/home/home.component';
import { SearchComponent } from './modules/search/search.component';
import { TestimoniosComponent } from './modules/testimonios/testimonios.component';
import { VenderComponent } from './modules/quiero-vender/quiero-vender.component';
import { ComprarComponent } from './modules/quiero-comprar/quiero-comprar.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: HomeComponent,
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'user',
        loadChildren: () =>
          import('./modules/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'publishment',
        loadChildren: () =>
          import('./modules/publishment/publishment.module').then(
            (m) => m.PublishingModule
          ),
      },
      {
        path: 'search',
        component: SearchComponent,
        data: {
          breadcrumb: [
            { path: 'inicio', url: ['/home'] },
            { path: 'buscar', url: ['/search'] },
          ],
        },
      },
      {
        path: 'testimonios',
        component: TestimoniosComponent,
        data: {
          breadcrumb: [
            { path: 'inicio', url: ['/home'] },
            { path: 'testimonios', url: ['/testimonios'] },
          ],
        },
      },
      {
        path: 'quiero-vender',
        component: VenderComponent,
        data: {
          breadcrumb: [
            { path: 'inicio', url: ['/home'] },
            { path: 'quiero vender', url: ['/quiero-vender'] },
          ],
        },
      },
      {
        path: 'quiero-comprar',
        component: ComprarComponent,
        data: {
          breadcrumb: [
            { path: 'inicio', url: ['/home'] },
            { path: 'quiero comprar', url: ['/quiero-comprar'] },
          ],
        },
      },
      {
        path: 'catalog/:holidayId',
        component: CatalogComponent,
        data: {
          breadcrumb: [
            { path: 'inicio', url: ['/home'] },
            { path: 'catalogo', url: ['/search'] },
          ],
        },
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./modules/backoffice/backoffice.module').then(
            (m) => m.BackofficeModule
          ),
      },
      {
        path: 'support',
        loadChildren: () =>
          import('./modules/support/support.module').then(
            (m) => m.SupportModule
          ),
      },
      {
        path: '**',
        redirectTo: 'home',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      /*  useHash: true, */
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
