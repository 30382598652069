import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vender',
  templateUrl: './quiero-vender.component.html',
  styleUrls: ['./quiero-vender.component.scss'],
})
export class VenderComponent implements OnInit {
  currentPage: number;
  perPage: number;
  location: string;
  query: string;
  @ViewChild('tabParent') Element: ElementRef;

  constructor(private router: Router) {}

  ngOnInit(): void {}
}
